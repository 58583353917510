import countryCode from './phoneCode.json';

export const getIntlPhoneNumber = (phone: string, country: string) => {
  const countryCodeMap = new Map(countryCode.map(c => [c.code, c.phone]));
  const intlPrefix = countryCodeMap.get(country);

  const normalizedPhone = `+${!intlPrefix ? '39' : intlPrefix}${phone.replace(/\D/g, "")}`;
  return normalizedPhone;
};

