import ClaimsOperationIcon, {
	Sign,
} from 'components/commons/ClaimIcons/ClaimsOperationIcon.component';
import {
	calculateEstimates,
	setCalculated,
	setOtherServices,
	setStatusCalculation,
} from 'feature/claims/claimSlice';
import { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';
import {
	BoxOverflow,
	Button,
	Stack,
	Table,
	TableBody,
	TableCell as TableCellUnstyled,
	TableHead,
	TableRow,
	Typography,
} from 'styles';
import { IClaim, IClaimComponents } from 'types/Claim';
import {
	formatPrice,
	getRangeValueAmount,
	useGetIsOtherComponent,
} from 'utils/utils';

import { uniqueId } from 'lodash';
import styled from 'styled-components';
import InputNumber from 'styles/input/InputNumber';
import { breakpoints } from 'styles/theme/Breakpoints';
import palette from 'styles/theme/Palette';
import { useTranslate } from 'stylesHooks';
import { IAmountLimit } from 'types/Member';
import { TRANSLATION_KEYS } from 'utils/constants';
import OtherItemsSection from './OtherItemsSection';
import StandardItemsSection from './StandardItemsSection';

type prescriptionProps = {
	formData: IClaim;
	setFormData: (changeFunction: (prevValue: IClaim) => IClaim) => void;
	status: 'beforeCalculation' | 'afterCalculation' | 'onEdit';
	showGross?: boolean;
};

const PriceEstimate: FC<prescriptionProps> = props => {
	const { t: tClientLabels } = useTranslate(TRANSLATION_KEYS.CLIENT_LABELS);
	const dispatch = useDispatch<AppDispatch>();

	const { formData, status, showGross = true } = props;
	const amount = formData.amount;

	const [tempAmounts, setTempAmounts] = useState<IClaim['amount']>(amount);
	const [isError, setIsError] = useState<boolean>(false);
	const [maxValueError, setMaxValueError] = useState<boolean>(false);
	const [benefit, setBenefit] = useState<
		{ amountLimits: IAmountLimit[]; serviceId: number }[]
	>([]);
	const calculated = useSelector((state: RootState) => state.claim.calculated);
	const { member } = useSelector((state: RootState) => state.member);
	const { otherServices } = useSelector((state: RootState) => state.claim);

	const { isOtherComponent } = useGetIsOtherComponent();

	useEffect(() => {
		const otherServices =
			member?.benefit?.others.map(el => ({
				serviceLabel: el.serviceLabel,
				serviceId: el.serviceId,
			})) || [];

		dispatch(setOtherServices(otherServices));
	}, [dispatch, member]);

	useEffect(() => {
		if (member) {
			const benefits = [
				...(member?.benefit ? member.benefit.exam : []),
				...(member?.benefit ? member.benefit.materials : []),
				...(member?.benefit ? member.benefit.others : []),
			];
			const newBenefit = benefits.map(el => ({
				amountLimits: el.amountLimits,
				serviceId: el.serviceId,
			}));
			setBenefit(newBenefit);
		}
	}, [member]);

	useEffect(() => {
		//se initial value
		dispatch(
			setStatusCalculation(
				formData.amount.components.find(el => !el.retailAmount)
					? 'beforeCalculation'
					: 'afterCalculation',
			),
		);
	}, [dispatch, formData.amount.components]);

	useEffect(() => {
		if (calculated) {
			dispatch(setStatusCalculation('afterCalculation'));
			dispatch(setCalculated(false));
		}
	}, [calculated, dispatch, amount]);

	const resetTempAmounts = useCallback(
		(amounts: IClaim['amount']) => {
			setTempAmounts(() => ({
				...amounts,
				components: amounts.components.map(el => {
					if (
						otherServices.map(otherEl => otherEl.serviceId).includes(el.serviceId)
					) {
						return {
							serviceId: el.serviceId,
							retailAmount: el.retailAmount,
							grossRetail: el.grossRetail,
							rowId: uniqueId(),
						};
					}
					return {
						serviceId: el.serviceId,
						retailAmount: el.retailAmount,
						grossRetail: el.grossRetail,
					};
				}),
				total: {
					serviceId: null,
					retailAmount: '',
					grossRetail: 0,
				},
				accumulator: undefined,
			}));
		},
		[otherServices],
	);

	useEffect(() => {
		if (['onEdit', 'beforeCalculation'].includes(status)) {
			resetTempAmounts(amount);
		} else {
			setTempAmounts(amount);
		}
	}, [amount, status, resetTempAmounts]);

	const handleChangeAmounts = (
		formattedValue: string,
		serviceId: number | null,
		key: string,
	) => {
		const newComponents = tempAmounts.components.map(el => {
			if (el.serviceId === serviceId) {
				return {
					...el,
					[key]: formattedValue,
				};
			}

			return el;
		});

		const newTempAmounts = {
			...tempAmounts,
			components: newComponents,
		};

		setMaxValueError(false);

		if (isError) {
			const { components } = newTempAmounts;
			const error = components.some(
				el =>
					parseFloat(el.grossRetail?.toString() || '') <
					parseFloat(el.retailAmount?.toString() || ''),
			);

			if (!error) {
				setIsError(false);
			}
		}

		setTempAmounts(newTempAmounts);
	};

	const addCurrency = (value: number | string | undefined) => {
		return ['afterCalculation'].includes(status)
			? formatPrice(value ?? '').replace('€', '€ ')
			: '';
	};

	const handleCalculate = () => {
		const { components } = tempAmounts;
		const error =
			showGross &&
			components.some(
				el =>
					parseFloat(el.grossRetail?.toString() || '') <
					parseFloat(el.retailAmount?.toString() || ''),
			);

		const isMaxValueError = components.some(
			el =>
				parseFloat(el.grossRetail?.toString() || '') >
				(getRangeValueAmount(el.serviceId, benefit, 'gross-retail-amount').max ||
					0),
		);
		setMaxValueError(isMaxValueError);

		if (error) {
			setIsError(true);
		} else if (!error && !isMaxValueError) {
			const newComponents = tempAmounts.components.filter(el => el.serviceId); //to ignore empty rows

			const newTempAmounts = {
				...tempAmounts,
				components: newComponents,
			};

			setTempAmounts(newTempAmounts);

			dispatch(calculateEstimates({ ...formData, amount: newTempAmounts }));
		}
	};

	const checkIsError = (row: IClaimComponents) => {
		let error = false;
		if (isError) {
			error =
				parseFloat(row.grossRetail?.toString() || '') <
				parseFloat(row.retailAmount?.toString() || '');
		}
		if (maxValueError) {
			error =
				parseFloat(row.grossRetail?.toString() || '') >
				(getRangeValueAmount(row.serviceId, benefit, 'gross-retail-amount').max ||
					0);
		}
		return error;
	};

	const totals = tempAmounts.total;
	const editMode = ['beforeCalculation', 'onEdit'].includes(status);

	return (
		<Stack gap="30px">
			<BoxOverflow variant="x" sx={{ width: '98%' }}>
				<Stack gap="20px" sx={{ minWidth: 1500 }}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell sx={{ minWidth: '180px' }}>
									<Typography variant="bodyLink" sx={{ pl: '10px' }}>
										{tClientLabels('newClaim.priceEstimate.visionProduct')}:
									</Typography>
								</TableCell>
								{showGross ? (
									<>
										<TableCell align="center">
											<Typography variant="bodyLink">
												{tClientLabels('newClaim.priceEstimate.grossRetail')}
											</Typography>
										</TableCell>
										<TableCell align="center" $smallIcon>
											<ClaimsOperationIcon sign={Sign.MINUS} />
										</TableCell>
										<TableCell align="center">
											<Typography variant="bodyLink">
												{tClientLabels('newClaim.priceEstimate.inStorePromotion')}
											</Typography>
										</TableCell>
										<TableCell align="center">
											<ClaimsOperationIcon sign={Sign.EQUAL} />
										</TableCell>
									</>
								) : null}
								<TableCell align="center">
									<Typography variant="bodyLink">
										{tClientLabels('newClaim.priceEstimate.retailAmount')}
									</Typography>
								</TableCell>
								<TableCell align="center" $smallIcon>
									<ClaimsOperationIcon sign={Sign.MINUS} />
								</TableCell>
								<TableCell align="center">
									<Typography variant="bodyLink">
										{tClientLabels('newClaim.priceEstimate.contractualAdjustments')}
									</Typography>
								</TableCell>
								<TableCell align="center">
									<ClaimsOperationIcon sign={Sign.EQUAL} />
								</TableCell>
								<TableCell align="center">
									<Typography variant="bodyLink">
										{tClientLabels('newClaim.priceEstimate.memberPay')}
									</Typography>
								</TableCell>
								<TableCell align="center" $smallIcon>
									<ClaimsOperationIcon sign={Sign.PLUS} />
								</TableCell>
								<TableCell align="center">
									<Typography variant="bodyLink">
										{tClientLabels('newClaim.priceEstimate.planPay')}
									</Typography>
								</TableCell>
								{editMode &&
								tempAmounts.components.filter(el => isOtherComponent(el)).length > 0 ? (
									<TableCell
										align="center"
										sx={{
											backgroundColor: theme => theme.palette.grey6.main,
											position: 'sticky',
											right: 0,
											width: 44,
										}}
									></TableCell>
								) : null}
							</TableRow>
						</TableHead>
						<TableBody>
							<StandardItemsSection
								editMode={editMode}
								isError={isError}
								tempAmounts={tempAmounts}
								setTempAmounts={setTempAmounts}
								checkIsError={checkIsError}
								handleChangeAmounts={handleChangeAmounts}
								isOtherComponent={isOtherComponent}
								addCurrency={addCurrency}
								showGross={showGross}
								status={status}
							/>
							<OtherItemsSection
								editMode={editMode}
								isError={isError}
								tempAmounts={tempAmounts}
								setTempAmounts={setTempAmounts}
								checkIsError={checkIsError}
								handleChangeAmounts={handleChangeAmounts}
								isOtherComponent={isOtherComponent}
							/>
							<TableRow sx={{ height: { sm: 56, lg: 70 } }}>
								<TableCell sx={{ width: { sm: 136, lg: 230 } }}>
									<Typography variant="bodyLink" sx={{ pl: '10px' }}>
										{tClientLabels('newClaim.priceEstimate.totals')}:
									</Typography>
								</TableCell>
								{showGross ? (
									<>
										<TableCell align="center">
											<Typography variant="bodyLink">
												{addCurrency(totals.grossRetail)}
											</Typography>
										</TableCell>
										<TableCell align="center" $smallIcon></TableCell>
										<TableCell align="center">
											<Typography variant="bodyLink">
												{addCurrency(totals.inStorePromotion)}
											</Typography>
										</TableCell>
										<TableCell align="center" $smallIcon></TableCell>
									</>
								) : null}
								<TableCell align="center">
									<Typography variant="bodyLink">
										{addCurrency(totals.retailAmount)}
									</Typography>
								</TableCell>
								<TableCell align="center" $smallIcon></TableCell>
								<TableCell align="center">
									<Typography variant="bodyLink">
										{addCurrency(totals.contractualAdjustmentAmount)}
									</Typography>
								</TableCell>
								<TableCell align="center" $smallIcon>
									<Typography variant="bodyLink" style={{ color: palette.grey2.main }}>
										{addCurrency(totals.discountedAmount)}
									</Typography>
								</TableCell>
								<TableCell align="center">
									<Typography variant="bodyLink">
										{addCurrency(totals.memberPayAmount)}
									</Typography>
								</TableCell>
								<TableCell align="center" $smallIcon></TableCell>
								<TableCell align="center">
									<Typography variant="bodyLink">
										{addCurrency(totals.allowablePlanPay)}
									</Typography>
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</Stack>
				<Stack>
					{isError && (
						<Typography variant="bodyLink" color={palette.error.main}>
							{tClientLabels('newClaim.priceEstimate.grossRetailError')}
						</Typography>
					)}
					{maxValueError && (
						<Typography variant="bodyLink" color={palette.error.main}>
							{tClientLabels('newClaim.priceEstimate.maxAmountError')}
						</Typography>
					)}
				</Stack>
			</BoxOverflow>
			{['beforeCalculation', 'onEdit'].includes(status) ? (
				<Stack direction={'row'} gap="20px">
					{['onEdit'].includes(status) ? (
						<Button
							variant="outlined"
							onClick={() => {
								dispatch(setStatusCalculation('afterCalculation'));
								setTempAmounts(amount);
								setIsError(false);
								setMaxValueError(false);
							}}
						>
							{tClientLabels('commons.buttons.cancel')}
						</Button>
					) : null}
					<Button
						disabled={
							Boolean(
								tempAmounts.components.find(
									el =>
										el.serviceId && (!el.retailAmount || (!el.grossRetail && showGross)),
								),
							) || isError
						}
						variant="contained"
						onClick={handleCalculate}
					>
						{tClientLabels('newClaim.priceEstimate.calculate')}
					</Button>
				</Stack>
			) : null}
		</Stack>
	);
};

type TableCellProps = typeof TableCellUnstyled & {
	$smallIcon?: boolean;
};

export const TableCell = styled(TableCellUnstyled)<TableCellProps>`
	&.MuiTableCell-head {
		padding: ${({ $smallIcon }) =>
			$smallIcon ? '8px 0px 8px 0px' : '8px 0px 8px 20px'};
		width: ${({ $smallIcon }) => ($smallIcon ? '24px' : 'auto')};
	}
	padding: ${({ $smallIcon }) =>
		$smallIcon ? '8px 0px 8px 0px' : '8px 0px 8px 20px'};
	@media (min-width: ${breakpoints.values.sm}px) {
		&.MuiTableCell-head {
			padding: ${({ $smallIcon }) =>
				$smallIcon ? '8px 0px 8px 0px' : '8px 0px 8px 10px'};
			width: ${({ $smallIcon }) => ($smallIcon ? '24px' : 'auto')};
		}
		padding: ${({ $smallIcon }) =>
			$smallIcon ? '8px 0px 8px 0px' : '8px 0px 8px 10px'};
	}
`;

export const OutlinedInput = styled(InputNumber)`
	width: 91px;
	height: 28px;
	border-radius: 4px;
	padding-right: 8px;
	input {
		text-align: right;
		margin-right: 0px;
		margin-bottom: 16px;
		padding-right: 0px;
		padding-left: 8px;
		font-weight: 400;
		font-size: 13px;
	}
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	input[type='text'] {
		appearance: none;
		-moz-appearance: textfield;
	}
	.MuiInputBase-root,
	.MuiInputBase-root:hover,
	.MuiInputBase-root:focus {
		background-color: transparent !important;
	}
`;

export default PriceEstimate;
