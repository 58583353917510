import { ReactComponent as DeleteIcon } from 'assett/icons/delete_outline.svg';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { IconButton, Select, TableRow, Typography } from 'styles';
import SelectItem from 'styles/select/SelectItem';
import { theme } from 'styles/theme/ThemeStyle';
import { useTranslate } from 'stylesHooks';
import { IClaim, IClaimComponents } from 'types/Claim';
import { TRANSLATION_KEYS } from 'utils/constants';
import { formatInputNumberValue, formatPrice } from 'utils/utils';
import { OutlinedInput, TableCell } from './PriceEstimate';

type OtherReceiptItemRowProps = {
	tempAmounts: IClaim['amount'];
	editMode: boolean;
	row: IClaimComponents;
	removeComponent: (rowNumber: number) => void;
	handleChangeServiceName: (rowNumber: number, serviceId: number) => void;
	isError: boolean;
	checkIsError: (row: IClaimComponents) => boolean;
	handleChangeAmounts: (
		formattedValue: string,
		serviceId: number | null,
		key: string,
	) => void;
	isOtherComponent: (row: IClaimComponents) => boolean;
	rowNumber: number;
};

const OtherReceiptItemRow: FC<OtherReceiptItemRowProps> = ({
	tempAmounts,
	editMode,
	row,
	removeComponent,
	handleChangeServiceName,
	isError,
	checkIsError,
	handleChangeAmounts,
	isOtherComponent,
	rowNumber,
}) => {
	const { t: tClientLabels } = useTranslate(TRANSLATION_KEYS.CLIENT_LABELS);
	const addCurrency = (value: number | string | undefined) => {
		return formatPrice(value ?? '').replace('€', '€ ');
	};
	const { otherServices } = useSelector((state: RootState) => state.claim);

	const isServiceSelected = (serviceId: number) => {
		return !tempAmounts.components
			.filter(el => isOtherComponent(el))
			.map(otherEl => otherEl.serviceId)
			.includes(serviceId);
	};

	return (
		<TableRow
			sx={{
				height: { sm: 56, lg: 70 },
				backgroundColor: theme.palette.white.main,
				outline: `2px solid ${theme.palette.grey6.main}`,
			}}
		>
			<TableCell sx={{ width: { sm: 136, lg: 230 }, pt: 0 }}>
				{editMode ? (
					<Select
						value={row.serviceId ? row.serviceId : ''}
						onChange={value => {
							handleChangeServiceName(rowNumber, value.target.value as number);
						}}
						sx={{
							height: '34px',
							width: '160px',
							fontSize: '13px',
							fontWeight: 600,
							textAlign: 'left',
							ml: '10px',

							'& .MuiSelect-icon': {
								top: 'auto',
							},
						}}
						MenuProps={{
							autoFocus: false,
							MenuListProps: {
								sx: {
									position: 'relative',
									display: 'flex',
									flexDirection: 'column',
									justifyItems: 'center',
									'& .MuiButtonBase-root.MuiMenuItem-root.Mui-selected, .Mui-selected:hover':
										{
											backgroundColor: theme => theme.palette.grey6.main,
										},
								},
							},
							PaperProps: {
								sx: {
									paddingTop: 0,
									paddingBottom: 0,
								},
							},
						}}
					>
						{otherServices?.map((e, index) =>
							e.serviceId ? (
								<SelectItem
									value={e.serviceId}
									sx={{
										height: 34,
										pl: '10px',
										pr: '10px',
										borderTop: index >= 1 ? '1px solid' : 'none',
										borderColor: theme.palette.grey4.main,
									}}
									variant="checkbox"
									disabled={!isServiceSelected(e.serviceId)}
									key={`day_${index}`}
								>
									{tClientLabels(
										`newClaim.priceEstimate.otherReceiptItems.${e.serviceLabel}`,
									)}
								</SelectItem>
							) : (
								<></>
							),
						)}
					</Select>
				) : (
					<Typography variant="body" sx={{ pl: '10px' }}>
						{tClientLabels(
							`newClaim.priceEstimate.otherReceiptItems.${
								otherServices.find(el => el.serviceId === row.serviceId)?.serviceLabel
							}`,
						)}
					</Typography>
				)}
			</TableCell>
			<TableCell align="center" sx={{ width: { sm: 136, lg: 230 } }}>
				{editMode ? (
					<OutlinedInput
						error={checkIsError(row)}
						isCurrency={true}
						value={formatInputNumberValue(row.grossRetail || '')}
						handleInputNumberChange={formattedValue => {
							handleChangeAmounts(formattedValue, row.serviceId, 'grossRetail');
						}}
						disabled={row.serviceId === null}
					/>
				) : (
					<Typography variant="value1">{addCurrency(row.grossRetail)}</Typography>
				)}
			</TableCell>
			<TableCell align="center"></TableCell>
			<TableCell align="center"></TableCell>
			<TableCell align="center"></TableCell>
			<TableCell align="center" sx={{ width: { sm: 136, lg: 230 } }}>
				{editMode ? (
					<OutlinedInput
						error={
							isError &&
							parseFloat(row.grossRetail?.toString() || '') <
								parseFloat(row.retailAmount?.toString() || '')
						}
						isCurrency={true}
						value={formatInputNumberValue(row.retailAmount || '')}
						handleInputNumberChange={formattedValue => {
							handleChangeAmounts(formattedValue, row.serviceId, 'retailAmount');
						}}
						disabled={row.serviceId === null}
					/>
				) : (
					<Typography variant="value1">{addCurrency(row.retailAmount)}</Typography>
				)}
			</TableCell>
			<TableCell align="center"></TableCell>
			<TableCell align="center"></TableCell>
			<TableCell align="center"></TableCell>
			<TableCell align="center"></TableCell>
			<TableCell align="center"></TableCell>
			<TableCell align="center"></TableCell>
			{editMode ? (
				<TableCell
					align="center"
					sx={{
						backgroundColor: theme => theme.palette.grey6.main,
						position: 'sticky',
						right: 0,
						width: 44,
					}}
				>
					<IconButton onClick={() => removeComponent(rowNumber)}>
						<DeleteIcon />
					</IconButton>
				</TableCell>
			) : null}
		</TableRow>
	);
};

export default OtherReceiptItemRow;
